import React, { useState, useContext } from "react";
import SendMessageModal from "../../message-modality/message-modality";
import { ApiContext } from "../../../context/apiContext";
import { toProperCase } from "../../../utilities/utility";
import { toast } from 'react-toastify'

import {
    Canvas,
    IconButton,
    Th,
    ApplicantName,
    BulletIcon,
    StatusBadge,
    ContactDiv,
    StyledTable,
    TableRow,
    TableCellWrapper,
    IconDiv,
    FlexDivColumn,
    FlexDiv,
    Heading,
    SubscriptText,
    ApplicantDetails,
    ApplicationDiv,
    ApplicationStatusBadge
} from './tenant-info.styles'

import { ReactComponent as Message } from '../../../assets/Message.svg'
import { ReactComponent as Reminder } from '../../../assets/reminder.svg'


const TenantInfo = ({ tenants, applicants, renewalId, applicationStatus, setActiveTab}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tenantsToSend, setTenantsToSend] = useState([]);
    const api = useContext(ApiContext)

    const handleOpenModalForAll = () => {
        setTenantsToSend(tenants); // Set all tenants
        setIsModalOpen(true);
    };

    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const sendReminder = async (id, type) => {
        try {
            const data = await api.get(`/api/renewal/remind`,{tenantId: id, renewalId: renewalId});
            toast.info(`Reminder sent to ${type}`);
        }catch(error){
            toast.error(error);
        }
    }
    // console.log(tenants)
    return (
        <Canvas>
            <StyledTable>
                <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                    <tr>
                        <Th><TableCellWrapper>Tenant</TableCellWrapper></Th>
                        <Th><TableCellWrapper>Renewal Status</TableCellWrapper></Th>
                        {/* <Th><TableCellWrapper>W8/W9 Status</TableCellWrapper></Th> */}
                        <Th><TableCellWrapper><ContactDiv onClick={handleOpenModalForAll}>Email All</ContactDiv></TableCellWrapper></Th>
                    </tr>
                </thead>
                {
                    tenants.map(tenant => 
                        <TableRow>
                            <ApplicantName><TableCellWrapper>{tenant.name}</TableCellWrapper></ApplicantName>
                            <td>
                                <TableCellWrapper>
                                    <StatusBadge status = {tenant.renewalStatus}>
                                        <BulletIcon/>
                                        {tenant.renewalStatus}
                                    </StatusBadge>
                                </TableCellWrapper>
                            </td>
                            <td>
                                <IconDiv>
                                    <TableCellWrapper>
                                        <IconButton onClick={() => handleOpenModalForOne(tenant)}>
                                            <Message/>
                                        </IconButton>
                                    </TableCellWrapper>
                                    <TableCellWrapper>
                                        <IconButton 
                                            onClick = {() => sendReminder(tenant.tenantId, 'tenant')}
                                        >
                                            <Reminder/>
                                        </IconButton>
                                    </TableCellWrapper>
                                </IconDiv>
                            </td>
                        </TableRow>
                    )
                }
            </StyledTable>
            {
                applicants.length > 0 &&
                <FlexDivColumn>
                    <FlexDiv>
                        <Heading>New Applicants</Heading>
                        <ApplicationStatusBadge status={toProperCase(applicationStatus)}>
                            <BulletIcon/>
                            {toProperCase(applicationStatus)}
                        </ApplicationStatusBadge>
                        <ApplicationDiv onClick={() => setActiveTab('Application')}>Application Package</ApplicationDiv>
                    </FlexDiv>
                    {
                        applicants.map(applicant => 
                            <ApplicantDetails>
                                <div>
                                    <ApplicantName>{applicant.name}</ApplicantName>
                                    <SubscriptText>{applicant.applicantType}</SubscriptText>
                                </div>
                                <IconDiv onClick={() => handleOpenModalForOne(applicant)}>
                                    <IconButton>
                                        <Message/>
                                    </IconButton>
                                </IconDiv>
                            </ApplicantDetails>
                        )
                    }
                </FlexDivColumn>
            }
            
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={applicants}
                setTenantsToSend={setTenantsToSend}
            />
        </Canvas>
    )
}

export default TenantInfo

