import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';

import {
    ModalBackground,
    ModalContainer,
    ModalHeader,
    ConfirmationMessage,
    ActionButtonsContainer,
    ConfirmButton,
    CancelButton,
    RemarksContainer,
    Remarks
} from './reject-modal.styles'
import { ApiContext } from '../../context/apiContext';



// Example of how to use these in a component
const RejectionModal = ({ onClose, applicationId, updateApplication, renewalId = null }) => {

    const [remark, setRemark] = useState('');

    const api = useContext(ApiContext)
    const cancelClick = (event) => {
        event.stopPropagation();
        onClose();
    }

    const confirmRejection = async (event) => {
        event.preventDefault(); // If this is triggered by a form, use preventDefault to stop the form from submitting. Use stopPropagation if needed for other cases.
    
        let payload;
    
        try {
            // Use the put method from your ApiContext to make the API call
            let data;
            if(renewalId){
                payload = {
                    renewalId: renewalId,
                    applicationId: applicationId,
                    applicationStatus: 'Rejected'
                }
                data = await api.put('/api/renewal/application-update', payload);
            }else{
                payload = {
                    applicationId: applicationId,
                    remark: remark
                };
                data = await api.put(`/api/application/reject`, payload);
            }
            
            updateApplication(data); // Update your application state with the new data
            onClose(); // Close the modal or perform other cleanup actions
        } catch (error) {
            // Errors are already handled and logged in the ApiContext, so you can handle user feedback here if needed
            console.error('Error rejecting application', error);
        }
    };

    const handleModalClick = (event) => {
        event.stopPropagation(); // Prevent click from propagating to the background
    };


    return ReactDOM.createPortal(
        <ModalBackground onClick={cancelClick}>
            <ModalContainer onClick = {handleModalClick}>
                <ModalHeader>Confirm Rejection</ModalHeader>
                <ConfirmationMessage>Are you sure you want to reject this application?</ConfirmationMessage>
                <RemarksContainer>
                    <Remarks
                        id = 'remarks'
                        rows={5} // Number of rows
                        cols={50} // Number of columns
                        onChange={(event) => setRemark(event.target.value)}
                    />
                </RemarksContainer>
                <ActionButtonsContainer>
                    <CancelButton onClick={cancelClick}>Cancel</CancelButton>
                    <ConfirmButton onClick={confirmRejection}>Confirm</ConfirmButton>
                </ActionButtonsContainer>
            </ModalContainer>
        </ModalBackground>,
        document.getElementById('modal-root')
    );
};

export default RejectionModal;
