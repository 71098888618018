import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PropertyInfo from "../../components/property-info/property-info";
import moment from "moment";
import {
    Canvas, Title, Section, Heading, CancelButton, SubmitButton, RowContainer, InnerSection
} from './renewal-status.pages.styles'
import { formatCurrency } from "../../utilities/utility";

const RenewalStatus = () => {
    const {renewalId, tenantId} = useParams();

    const [renewalData, setRenewalData] = useState();
    const [currentTenant, setCurrentTenant] = useState();
    const [isFocus, setIsFocus] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`/api/renewal/tenant-detail?tenantId=${tenantId}&renewalId=${renewalId}`);
                if (response.ok) {                    
                    let data = await response.json();
                    setRenewalData(data);
                    const currentTenantFocus = data.tenants.find(tenant => 
                        tenant.tenantId == tenantId
                    )
                    setCurrentTenant(currentTenantFocus)
                } else {
                    // Handle errors, e.g., response status not OK
                    throw Error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
            // const data = {
            //     "renewalId": 1,
            //     "property": {
            //             "address": "47 Englewood Ave. #6 Brighton",
            //             "moveOut": "2025-03-03",
            //             "imageUrl": "https://ygl-photos.s3.us-west-004.backblazeb2.com/340W66fc1c0334938.jpg",
            //             "price": 3175.0
            //     },
            //     "renewalStatus": "Pending",
            //     "totalTenants": 2,
            //     "latestUpdateDate": "2025-01-14T04:28:42.867052Z",
            //     "deadline": "2025-01-13",
            //     "latestOfferPrice": 3175.0,
            //     "tenants": [{
            //         "tenantId": 1,
            //         "name": "new",
            //         "email": "roombae3@gmail.com",
            //         "type": "Student",
            //         "phone": null,
            //         "renewalFormStatus": "Pending",
            //         "renewalStatus": "Pending",
            //         "leaseStatus": "Pending",
            //         "renewalForm": "None"
            //     }]
            //   }

              
        };
        fetchData();
    }, [tenantId, renewalId])

    const handleResponse = async (responseType) => {
        try {
            // Build the request payload
            const requestData = {
                renewalId: renewalId,
                status: responseType,
                tenantId: tenantId,
            };
    
            // Make the API call using fetch
            const response = await fetch("/api/renewal/tenant-response", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });
    
            // Check if the response is successful
            if (response.ok) {
                const responseData = await response.json(); // Parse the JSON response
                setIsFocus('Submit')
                toast.success(`Tenant response successfully updated to ${responseType}.`);
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            // Handle errors
            toast.error(`Failed to update tenant response: ${error.message}`);
            console.error("API Error:", error);
        }
    };

    console.log(currentTenant)
    

    return (
        <Canvas>
            {
                isFocus === "Submit"?
                <>
                    <Title style={{margin:'auto'}}>Thank you for your selection!</Title>
                </>
                :
                currentTenant?.renewalStatus === "Pending" ?
                <InnerSection>
                    <Section>
                        <Title>Renewal Request</Title>
                        <PropertyInfo
                            property={renewalData?.property}
                            offerPrice={renewalData?.latestOfferPrice}
                        />
                    </Section>
                    <Section>
                        <Heading>Your lease expires on {moment(renewalData.property.moveOut).format('MMM DD, YYYY')}. 
                            We are offering you the option to renew your lease for an updated rent of <strong>{formatCurrency(renewalData.latestOfferPrice)}</strong>.
                            Please let us know if you would like to continue renting with us latest by <strong>{moment(renewalData.deadline).format('MMM DD, YYYY')}</strong> by opting for one of the options below-
                        </Heading>
                    </Section>
                    <RowContainer>
                        <CancelButton onClick={()=>handleResponse('Denied')}>Decline</CancelButton>
                        <SubmitButton onClick={()=>handleResponse('Accepted')}>Accept</SubmitButton>
                    </RowContainer>
                    <p style={{fontSize:'12px', margin:'auto', fontStyle: 'italic'}}>*Once an option is selected, it cannot be reversed.</p>
                </InnerSection>
                :
                currentTenant?.renewalStatus === "Accepted" ?
                <>
                    <Title style={{margin:'auto'}}>You have already accepted the renewal request. We will reach out with next steps soon!</Title>
                </>
                :
                currentTenant?.renewalStatus === "Denied" ?
                <>
                    <Title style={{margin:'auto'}}>You have already denied the renewal request.</Title>
                </>
                :
                <>
                    <Title style={{margin:'auto'}}>Fetching Information...</Title>
                </>
            }
        </Canvas>
    )
}

export default RenewalStatus